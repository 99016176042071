import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const Datenschutz = () => {
  return (
    <Layout>
      <SEO title="Datenschutz " />
      <main className="page">
        <h1>Datenschutz</h1>
        <article>
          <div>
            <h2>Einleitung</h2>

            <p>
              Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
              aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
              auch kurz als “Daten“ bezeichnet) wir zu welchen Zwecken und in
              welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle
              von uns durchgeführten Verarbeitungen personenbezogener Daten,
              sowohl im Rahmen der Erbringung unserer Leistungen als auch
              insbesondere auf unseren Webseiten, in mobilen Applikationen sowie
              innerhalb externer Onlinepräsenzen, wie z.B. unserer
              Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als
              “Onlineangebot“).
            </p>

            <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>

            <p>Stand: 7. Oktober 2021</p>

            <h2>Inhaltsübersicht</h2>

            <ul>
              <li>Einleitung</li>
              <li>Verantwortlicher</li>
              <li>Übersicht der Verarbeitungen</li>
              <li>Maßgebliche Rechtsgrundlagen</li>
              <li>Sicherheitsmaßnahmen</li>
              <li>Übermittlung von personenbezogenen Daten</li>
              <li>Datenverarbeitung in Drittländern</li>
              <li>Löschung von Daten</li>
              <li>Einsatz von Cookies</li>
              <li>Bereitstellung des Onlineangebotes und Webhosting</li>
              <li>Blogs und Publikationsmedien</li>
              <li>Kommunikation via Messenger</li>
              <li>
                Videokonferenzen, Onlinemeetings, Webinare und
                Bildschirm-Sharing
              </li>
              <li>Cloud-Dienste</li>
              <li>Newsletter und elektronische Benachrichtigungen</li>
              <li>
                Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon
              </li>
              <li>Umfragen und Befragungen</li>
              <li>Webanalyse, Monitoring und Optimierung</li>
              <li>Onlinemarketing</li>
              <li>Affiliate-Programme und Affiliate-Links</li>
              <li>Präsenzen in sozialen Netzwerken (Social Media)</li>
              <li>Änderung und Aktualisierung der Datenschutzerklärung</li>
              <li>Rechte der betroffenen Personen</li>
              <li>Begriffsdefinitionen</li>
            </ul>

            <h2>Verantwortlicher</h2>

            <p>
              Felix Christian Buss
              <br />
              Große-Leege-Str. 43A
              <br />
              13055 Berlin
              <br />
              Deutschland
              <br />
              oder Kontakt unter
              <br />
              Kwiatowa 5
              <br />
              68-300 Lubsko
              <br />
              Polska
            </p>

            <p>E-Mail-Adresse: info@ideetipp.de</p>

            <p>
              Impressum:{" "}
              <a
                href="https://ideetipp.de"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://ideetipp.de
              </a>
            </p>

            <h2>Übersicht der Verarbeitungen</h2>

            <p>
              Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten
              und die Zwecke ihrer Verarbeitung zusammen und verweist auf die
              betroffenen Personen.
            </p>

            <h3>Arten der verarbeiteten Daten</h3>

            <ul>
              <li>
                Event-Daten (Facebook) (“Event-Daten” sind Daten, die z. B. via
                Facebook-Pixel (via Apps oder auf anderen Wegen) von uns an
                Facebook übermittelt werden können und sich auf Personen oder
                deren Handlungen beziehen; Zu den Daten gehören z. B. Angaben
                über Besuche auf Websites, Interaktionen mit Inhalten,
                Funktionen, Installationen von Apps, Käufe von Produkten, etc.;
                die Event-Daten werden zwecks Bildung von Zielgruppen für
                Inhalte und Werbeinformationen (Custom Audiences) verarbeitet;
                Event Daten beinhalten nicht die eigentlichen Inhalte (wie z. B.
                verfasste Kommentare), keine Login-Informationen und keine
                Kontaktinformationen (also keine Namen, E-Mail-Adressen und
                Telefonnummern). Event Daten werden durch Facebook nach maximal
                zwei Jahren gelöscht, die aus ihnen gebildeten Zielgruppen mit
                der Löschung unseres Facebook-Kontos).
              </li>
              <li>Bestandsdaten (z.B. Namen, Adressen).</li>
              <li>Inhaltsdaten (z.B. Eingaben in Onlineformularen).</li>
              <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
              <li>
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen).
              </li>
              <li>
                Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten).
              </li>
              <li>
                Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
                Kundenkategorie).
              </li>
            </ul>

            <h3>Kategorien betroffener Personen</h3>

            <ul>
              <li>
                Beschäftigte (z.B. Angestellte, Bewerber, ehemalige
                Mitarbeiter).
              </li>
              <li>Interessenten.</li>
              <li>Kommunikationspartner.</li>
              <li>Kunden.</li>
              <li>
                Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
            </ul>

            <h3>Zwecke der Verarbeitung</h3>

            <ul>
              <li>Affiliate-Nachverfolgung.</li>
              <li>
                Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
              </li>
              <li>
                Konversionsmessung (Messung der Effektivität von
                Marketingmaßnahmen).
              </li>
              <li>Büro- und Organisationsverfahren.</li>
              <li>Direktmarketing (z.B. per E-Mail oder postalisch).</li>
              <li>Zielgruppenbildung.</li>
              <li>Marketing.</li>
              <li>Kontaktanfragen und Kommunikation.</li>
              <li>
                Profile mit nutzerbezogenen Informationen (Erstellen von
                Nutzerprofilen).
              </li>
              <li>Remarketing.</li>
              <li>
                Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung
                wiederkehrender Besucher).
              </li>
              <li>Erbringung vertraglicher Leistungen und Kundenservice.</li>
              <li>
                Zielgruppenbildung (Bestimmung von für Marketingzwecke
                relevanten Zielgruppen oder sonstige Ausgabe von Inhalten).
              </li>
            </ul>

            <h3>Maßgebliche Rechtsgrundlagen</h3>

            <p>
              Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der
              DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten.
              Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO
              nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder
              Sitzland gelten können. Sollten ferner im Einzelfall speziellere
              Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der
              Datenschutzerklärung mit.
            </p>

            <ul>
              <li>
                <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO)</strong>{" "}
                – Die betroffene Person hat ihre Einwilligung in die
                Verarbeitung der sie betreffenden personenbezogenen Daten für
                einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
              </li>
              <li>
                <strong>
                  Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1
                  S. 1 lit. b. DSGVO)
                </strong>{" "}
                – Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
                Vertragspartei die betroffene Person ist, oder zur Durchführung
                vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
                betroffenen Person erfolgen.
              </li>
              <li>
                <strong>
                  Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)
                </strong>{" "}
                – Die Verarbeitung ist zur Wahrung der berechtigten Interessen
                des Verantwortlichen oder eines Dritten erforderlich, sofern
                nicht die Interessen oder Grundrechte und Grundfreiheiten der
                betroffenen Person, die den Schutz personenbezogener Daten
                erfordern, überwiegen.
              </li>
            </ul>

            <p>
              <strong>Nationale Datenschutzregelungen in Deutschland</strong>:
              Zusätzlich zu den Datenschutzregelungen der
              Datenschutz-Grundverordnung gelten nationale Regelungen zum
              Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz
              zum Schutz vor Missbrauch personenbezogener Daten bei der
              Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG
              enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum
              Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung
              besonderer Kategorien personenbezogener Daten, zur Verarbeitung
              für andere Zwecke und zur Übermittlung sowie automatisierten
              Entscheidungsfindung im Einzelfall einschließlich Profiling. Des
              Weiteren regelt es die Datenverarbeitung für Zwecke des
              Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick
              auf die Begründung, Durchführung oder Beendigung von
              Beschäftigungsverhältnissen sowie die Einwilligung von
              Beschäftigten. Ferner können Landesdatenschutzgesetze der
              einzelnen Bundesländer zur Anwendung gelangen.
            </p>

            <h2>Sicherheitsmaßnahmen</h2>

            <p>
              Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
              Berücksichtigung des Stands der Technik, der
              Implementierungskosten und der Art, des Umfangs, der Umstände und
              der Zwecke der Verarbeitung sowie der unterschiedlichen
              Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der
              Rechte und Freiheiten natürlicher Personen geeignete technische
              und organisatorische Maßnahmen, um ein dem Risiko angemessenes
              Schutzniveau zu gewährleisten.
            </p>

            <p>
              Zu den Maßnahmen gehören insbesondere die Sicherung der
              Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
              Kontrolle des physischen und elektronischen Zugangs zu den Daten
              als auch des sie betreffenden Zugriffs, der Eingabe, der
              Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung.
              Des Weiteren haben wir Verfahren eingerichtet, die eine
              Wahrnehmung von Betroffenenrechten, die Löschung von Daten und
              Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner
              berücksichtigen wir den Schutz personenbezogener Daten bereits bei
              der Entwicklung bzw. Auswahl von Hardware, Software sowie
              Verfahren entsprechend dem Prinzip des Datenschutzes, durch
              Technikgestaltung und durch datenschutzfreundliche
              Voreinstellungen.
            </p>

            <p>
              <strong>Kürzung der IP-Adresse</strong>: Sofern IP-Adressen von
              uns oder von den eingesetzten Dienstleistern und Technologien
              verarbeitet werden und die Verarbeitung einer vollständigen
              IP-Adresse nicht erforderlich ist, wird die IP-Adresse gekürzt
              (auch als “IP-Masking” bezeichnet). Hierbei werden die letzten
              beiden Ziffern, bzw. der letzte Teil der IP-Adresse nach einem
              Punkt entfernt, bzw. durch Platzhalter ersetzt. Mit der Kürzung
              der IP-Adresse soll die Identifizierung einer Person anhand ihrer
              IP-Adresse verhindert oder wesentlich erschwert werden.
            </p>

            <p>
              <strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unserem
              Online-Angebot übermittelten Daten zu schützen, nutzen wir eine
              SSL-Verschlüsselung. Sie erkennen derart verschlüsselte
              Verbindungen an dem Präfix https:// in der Adresszeile Ihres
              Browsers.
            </p>

            <h2>Übermittlung von personenbezogenen Daten</h2>

            <p>
              Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt
              es vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
              selbstständige Organisationseinheiten oder Personen übermittelt
              oder sie ihnen gegenüber offengelegt werden. Zu den Empfängern
              dieser Daten können z.B. mit IT-Aufgaben beauftragte Dienstleister
              oder Anbieter von Diensten und Inhalten, die in eine Webseite
              eingebunden werden, gehören. In solchen Fall beachten wir die
              gesetzlichen Vorgaben und schließen insbesondere entsprechende
              Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen,
              mit den Empfängern Ihrer Daten ab.
            </p>

            <h2>Datenverarbeitung in Drittländern</h2>

            <p>
              Sofern wir Daten in einem Drittland (d.h., außerhalb der
              Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR))
              verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme
              von Diensten Dritter oder der Offenlegung bzw. Übermittlung von
              Daten an andere Personen, Stellen oder Unternehmen stattfindet,
              erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben.
            </p>

            <p>
              Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder
              gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir
              die Daten nur in Drittländern mit einem anerkannten
              Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte
              Standardschutzklauseln der EU-Kommission, beim Vorliegen von
              Zertifizierungen oder verbindlicher internen
              Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO,
              Informationsseite der EU-Kommission:{" "}
              <a
                href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
              </a>
              ).
            </p>

            <h2>Löschung von Daten</h2>

            <p>
              Die von uns verarbeiteten Daten werden nach Maßgabe der
              gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung
              erlaubten Einwilligungen widerrufen werden oder sonstige
              Erlaubnisse entfallen (z.B. wenn der Zweck der Verarbeitung dieser
              Daten entfallen ist oder sie für den Zweck nicht erforderlich
              sind).
            </p>

            <p>
              Sofern die Daten nicht gelöscht werden, weil sie für andere und
              gesetzlich zulässige Zwecke erforderlich sind, wird deren
              Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden
              gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B.
              für Daten, die aus handels- oder steuerrechtlichen Gründen
              aufbewahrt werden müssen oder deren Speicherung zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              oder zum Schutz der Rechte einer anderen natürlichen oder
              juristischen Person erforderlich ist.
            </p>

            <p>
              Unsere Datenschutzhinweise können ferner weitere Angaben zu der
              Aufbewahrung und Löschung von Daten beinhalten, die für die
              jeweiligen Verarbeitungen vorrangig gelten.
            </p>

            <h2>Einsatz von Cookies</h2>

            <p>
              Cookies sind Textdateien, die Daten von besuchten Websites oder
              Domains enthalten und von einem Browser auf dem Computer des
              Benutzers gespeichert werden. Ein Cookie dient in erster Linie
              dazu, die Informationen über einen Benutzer während oder nach
              seinem Besuch innerhalb eines Onlineangebotes zu speichern. Zu den
              gespeicherten Angaben können z.B. die Spracheinstellungen auf
              einer Webseite, der Loginstatus, ein Warenkorb oder die Stelle, an
              der ein Video geschaut wurde, gehören. Zu dem Begriff der Cookies
              zählen wir ferner andere Technologien, die die gleichen Funktionen
              wie Cookies erfüllen (z.B. wenn Angaben der Nutzer anhand
              pseudonymer Onlinekennzeichnungen gespeichert werden, auch als
              “Nutzer-IDs” bezeichnet)
            </p>

            <p>
              <strong>
                Die folgenden Cookie-Typen und Funktionen werden unterschieden:
              </strong>
            </p>

            <ul>
              <li>
                <strong>
                  Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):
                </strong>
                &nbsp;Temporäre Cookies werden spätestens gelöscht, nachdem ein
                Nutzer ein Online-Angebot verlassen und seinen Browser
                geschlossen hat.
              </li>
              <li>
                <strong>Permanente Cookies:</strong>&nbsp;Permanente Cookies
                bleiben auch nach dem Schließen des Browsers gespeichert. So
                kann beispielsweise der Login-Status gespeichert oder bevorzugte
                Inhalte direkt angezeigt werden, wenn der Nutzer eine Website
                erneut besucht. Ebenso können die Interessen von Nutzern, die
                zur Reichweitenmessung oder zu Marketingzwecken verwendet
                werden, in einem solchen Cookie gespeichert werden.
              </li>
              <li>
                <strong>First-Party-Cookies:</strong>&nbsp;First-Party-Cookies
                werden von uns selbst gesetzt.
              </li>
              <li>
                <strong>
                  Third-Party-Cookies (auch: Drittanbieter-Cookies)
                </strong>
                : Drittanbieter-Cookies werden hauptsächlich von Werbetreibenden
                (sog. Dritten) verwendet, um Benutzerinformationen zu
                verarbeiten.
              </li>
              <li>
                <strong>
                  Notwendige (auch: essentielle oder unbedingt erforderliche)
                  Cookies:
                </strong>{" "}
                Cookies können zum einen für den Betrieb einer Webseite
                unbedingt erforderlich sein (z.B. um Logins oder andere
                Nutzereingaben zu speichern oder aus Gründen der Sicherheit).
              </li>
              <li>
                <strong>
                  Statistik-, Marketing- und Personalisierungs-Cookies
                </strong>
                : Ferner werden Cookies im Regelfall auch im Rahmen der
                Reichweitenmessung eingesetzt sowie dann, wenn die Interessen
                eines Nutzers oder sein Verhalten (z.B. Betrachten bestimmter
                Inhalte, Nutzen von Funktionen etc.) auf einzelnen Webseiten in
                einem Nutzerprofil gespeichert werden. Solche Profile dienen
                dazu, den Nutzern z.B. Inhalte anzuzeigen, die ihren
                potentiellen Interessen entsprechen. Dieses Verfahren wird auch
                als “Tracking”, d.h., Nachverfolgung der potentiellen Interessen
                der Nutzer bezeichnet. Soweit wir Cookies oder
                “Tracking”-Technologien einsetzen, informieren wir Sie gesondert
                in unserer Datenschutzerklärung oder im Rahmen der Einholung
                einer Einwilligung.
              </li>
            </ul>

            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Auf welcher
              Rechtsgrundlage wir Ihre personenbezogenen Daten mit Hilfe von
              Cookies verarbeiten, hängt davon ab, ob wir Sie um eine
              Einwilligung bitten. Falls dies zutrifft und Sie in die Nutzung
              von Cookies einwilligen, ist die Rechtsgrundlage der Verarbeitung
              Ihrer Daten die erklärte Einwilligung. Andernfalls werden die
              mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer
              berechtigten Interessen (z.B. an einem betriebswirtschaftlichen
              Betrieb unseres Onlineangebotes und dessen Verbesserung)
              verarbeitet oder, wenn der Einsatz von Cookies erforderlich ist,
              um unsere vertraglichen Verpflichtungen zu erfüllen.
            </p>

            <p>
              <strong>Speicherdauer: </strong>Sofern wir Ihnen keine expliziten
              Angaben zur Speicherdauer von permanenten Cookies mitteilen (z. B.
              im Rahmen eines sog. Cookie-Opt-Ins), gehen Sie bitte davon aus,
              dass die Speicherdauer bis zu zwei Jahre betragen kann.
            </p>

            <p>
              <strong>
                Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out):
              </strong>{" "}
              Abhängig davon, ob die Verarbeitung auf Grundlage einer
              Einwilligung oder gesetzlichen Erlaubnis erfolgt, haben Sie
              jederzeit die Möglichkeit, eine erteilte Einwilligung zu
              widerrufen oder der Verarbeitung Ihrer Daten durch
              Cookie-Technologien zu widersprechen (zusammenfassend als
              “Opt-Out” bezeichnet). Sie können Ihren Widerspruch zunächst
              mittels der Einstellungen Ihres Browsers erklären, z.B., indem Sie
              die Nutzung von Cookies deaktivieren (wobei hierdurch auch die
              Funktionsfähigkeit unseres Onlineangebotes eingeschränkt werden
              kann). Ein Widerspruch gegen den Einsatz von Cookies zu Zwecken
              des Onlinemarketings kann auch mittels einer Vielzahl von
              Diensten, vor allem im Fall des Trackings, über die Webseiten{" "}
              <a
                href="https://optout.aboutads.info"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://optout.aboutads.info
              </a>{" "}
              und{" "}
              <a
                href="https://www.youronlinechoices.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://www.youronlinechoices.com/
              </a>{" "}
              erklärt werden. Daneben können Sie weitere Widerspruchshinweise im
              Rahmen der Angaben zu den eingesetzten Dienstleistern und Cookies
              erhalten.
            </p>

            <script
              id="CookieDeclaration"
              src="https://consent.cookiebot.com/a9f4e91f-ee0a-4dab-ad68-271edaf2342d/cd.js"
              type="text/javascript"
              async=""
            ></script>

            <p>
              <strong>
                Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung
              </strong>
              : Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management
              ein, in dessen Rahmen die Einwilligungen der Nutzer in den Einsatz
              von Cookies, bzw. der im Rahmen des
              Cookie-Einwilligungs-Management-Verfahrens genannten
              Verarbeitungen und Anbieter eingeholt sowie von den Nutzern
              verwaltet und widerrufen werden können. Hierbei wird die
              Einwilligungserklärung gespeichert, um deren Abfrage nicht erneut
              wiederholen zu müssen und die Einwilligung entsprechend der
              gesetzlichen Verpflichtung nachweisen zu können. Die Speicherung
              kann serverseitig und/oder in einem Cookie (sogenanntes
              Opt-In-Cookie, bzw. mithilfe vergleichbarer Technologien)
              erfolgen, um die Einwilligung einem Nutzer, bzw. dessen Gerät
              zuordnen zu können. Vorbehaltlich individueller Angaben zu den
              Anbietern von Cookie-Management-Diensten, gelten die folgenden
              Hinweise: Die Dauer der Speicherung der Einwilligung kann bis zu
              zwei Jahren betragen. Hierbei wird ein pseudonymer
              Nutzer-Identifikator gebildet und mit dem Zeitpunkt der
              Einwilligung, Angaben zur Reichweite der Einwilligung (z. B.
              welche Kategorien von Cookies und/oder Diensteanbieter) sowie dem
              Browser, System und verwendeten Endgerät gespeichert.
            </p>

            <ul>
              <li>
                <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B.
                besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                lit. f. DSGVO).
              </li>
            </ul>

            <h2>Bereitstellung des Onlineangebotes und Webhosting</h2>

            <p>
              Um unser Onlineangebot sicher und effizient bereitstellen zu
              können, nehmen wir die Leistungen von einem oder mehreren
              Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von
              ihnen verwalteten Servern) das Onlineangebot abgerufen werden
              kann. Zu diesen Zwecken können wir Infrastruktur- und
              Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
              Datenbankdienste sowie Sicherheitsleistungen und technische
              Wartungsleistungen in Anspruch nehmen.
            </p>

            <p>
              Zu den im Rahmen der Bereitstellung des Hostingangebotes
              verarbeiteten Daten können alle die Nutzer unseres Onlineangebotes
              betreffenden Angaben gehören, die im Rahmen der Nutzung und der
              Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse,
              die notwendig ist, um die Inhalte von Onlineangeboten an Browser
              ausliefern zu können, und alle innerhalb unseres Onlineangebotes
              oder von Webseiten getätigten Eingaben.
            </p>

            <p>
              <strong>E-Mail-Versand und -Hosting</strong>: Die von uns in
              Anspruch genommenen Webhosting-Leistungen umfassen ebenfalls den
              Versand, den Empfang sowie die Speicherung von E-Mails. Zu diesen
              Zwecken werden die Adressen der Empfänger sowie Absender als auch
              weitere Informationen betreffend den E-Mailversand (z.B. die
              beteiligten Provider) sowie die Inhalte der jeweiligen E-Mails
              verarbeitet. Die vorgenannten Daten können ferner zu Zwecken der
              Erkennung von SPAM verarbeitet werden. Wir bitten darum, zu
              beachten, dass E-Mails im Internet grundsätzlich nicht
              verschlüsselt versendet werden. Im Regelfall werden E-Mails zwar
              auf dem Transportweg verschlüsselt, aber (sofern kein sogenanntes
              Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf
              den Servern, von denen sie abgesendet und empfangen werden. Wir
              können daher für den Übertragungsweg der E-Mails zwischen dem
              Absender und dem Empfang auf unserem Server keine Verantwortung
              übernehmen.
            </p>

            <p>
              <strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir
              selbst (bzw. unser Webhostinganbieter) erheben Daten zu jedem
              Zugriff auf den Server (sogenannte Serverlogfiles). Zu den
              Serverlogfiles können die Adresse und Name der abgerufenen
              Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene
              Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst
              Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
              besuchte Seite) und im Regelfall IP-Adressen und der anfragende
              Provider gehören.
            </p>

            <p>
              Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
              eingesetzt werden, z.B., um eine Überlastung der Server zu
              vermeiden (insbesondere im Fall von missbräuchlichen Angriffen,
              sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der
              Server und ihre Stabilität sicherzustellen.
            </p>

            <ul>
              <li>
                <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B.
                Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte
                Webseiten, Interesse an Inhalten, Zugriffszeiten),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
                Onlineangebotes und Nutzerfreundlichkeit, Erbringung
                vertraglicher Leistungen und Kundenservice.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art.
                6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>

            <p>
              <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
            </p>

            <ul>
              <li>
                <strong>1&amp;1 IONOS:</strong> Hostingplattform für E-Commerce
                / Websites; Dienstanbieter: 1&amp;1 IONOS SE, Elgendorfer Str.
                57, 56410 Montabaur, Deutschland; Website:{" "}
                <a
                  href="https://www.ionos.de"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://www.ionos.de
                </a>
                ; Datenschutzerklärung:{" "}
                <a
                  href="https://www.ionos.de/terms-gtc/terms-privacy"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://www.ionos.de/terms-gtc/terms-privacy
                </a>
                .
              </li>
              <li>
                <strong>WordPress.com:</strong> Hostingplattform für Blogs /
                Webseiten; Dienstanbieter: Automattic Inc., 60 29th Street #343,
                San Francisco, CA 94110, USA; Website:{" "}
                <a
                  href="https://wordpress.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://wordpress.com
                </a>
                ; Datenschutzerklärung:{" "}
                <a
                  href="https://automattic.com/de/privacy/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://automattic.com/de/privacy/
                </a>
                .
              </li>
            </ul>

            <h2>Blogs und Publikationsmedien</h2>

            <p>
              Wir nutzen Blogs oder vergleichbare Mittel der Onlinekommunikation
              und Publikation (nachfolgend “Publikationsmedium”). Die Daten der
              Leser werden für die Zwecke des Publikationsmediums nur insoweit
              verarbeitet, als es für dessen Darstellung und die Kommunikation
              zwischen Autoren und Lesern oder aus Gründen der Sicherheit
              erforderlich ist. Im Übrigen verweisen wir auf die Informationen
              zur Verarbeitung der Besucher unseres Publikationsmediums im
              Rahmen dieser Datenschutzhinweise.
            </p>

            <ul>
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B.
                Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
                Inhaltsdaten (z.B. Eingaben in Onlineformularen), Nutzungsdaten
                (z.B. besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Geräte-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Erbringung
                vertraglicher Leistungen und Kundenservice, Feedback (z.B.
                Sammeln von Feedback via Online-Formular).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
                vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>

            <h2>Kommunikation via Messenger</h2>

            <p>
              Wir setzen zu Zwecken der Kommunikation Messenger ein und bitten
              daher darum, die nachfolgenden Hinweise zur Funktionsfähigkeit der
              Messenger, zur Verschlüsselung, zur Nutzung der Metadaten der
              Kommunikation und zu Ihren Widerspruchsmöglichkeiten zu beachten.
            </p>

            <p>
              Sie können uns auch auf alternativen Wegen, z.B. via Telefon oder
              E-Mail, kontaktieren. Bitte nutzen Sie die Ihnen mitgeteilten
              Kontaktmöglichkeiten oder die innerhalb unseres Onlineangebotes
              angegebenen Kontaktmöglichkeiten.
            </p>

            <p>
              Im Fall einer Ende-zu-Ende-Verschlüsselung von Inhalten (d.h., der
              Inhalt Ihrer Nachricht und Anhänge) weisen wir darauf hin, dass
              die Kommunikationsinhalte (d.h., der Inhalt der Nachricht und
              angehängte Bilder) von Ende zu Ende verschlüsselt werden. Das
              bedeutet, dass der Inhalt der Nachrichten nicht einsehbar ist,
              nicht einmal durch die Messenger-Anbieter selbst. Sie sollten
              immer eine aktuelle Version der Messenger mit aktivierter
              Verschlüsselung nutzen, damit die Verschlüsselung der
              Nachrichteninhalte sichergestellt ist.
            </p>

            <p>
              Die Ende-zu-Ende-Verschlüsselung von Skype setzt deren Aktivierung
              voraus.
            </p>

            <p>
              <strong>WhatsApp</strong>:
            </p>

            <ul>
              <li>
                <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B.
                E-Mail, Telefonnummern), Nutzungsdaten (z.B. besuchte Webseiten,
                Interesse an Inhalten, Zugriffszeiten),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen), Inhaltsdaten (z.B. Eingaben in Onlineformularen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Kommunikationspartner.
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
                Kommunikation, Direktmarketing (z.B. per E-Mail oder
                postalisch).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                lit. f. DSGVO).
              </li>
            </ul>

            <p></p>

            <h2>
              Videokonferenzen, Onlinemeetings, Webinare und Bildschirm-Sharing
            </h2>

            <p>
              Wir setzen Plattformen und Anwendungen anderer Anbieter
              (nachfolgend bezeichnet als “Konferenzplattformen”) zu Zwecken der
              Durchführung von Video- und Audio-Konferenzen, Webinaren und
              sonstigen Arten von Video und Audio-Meetings (nachfolgend
              zusammenfassend bezeichnet als “Konferenz”) ein. Bei der Auswahl
              der Konferenzplattformen und ihrer Leistungen beachten wir die
              gesetzlichen Vorgaben.
            </p>

            <p>
              <strong>Durch Konferenzplattformen verarbeitete Daten:</strong> Im
              Rahmen der Teilnahme an einer Konferenz verarbeiten die
              Konferenzplattformen die im Folgenden genannten personenbezogene
              Daten der Teilnehmer. Der Umfang der Verarbeitung hängt zum einen
              davon ab, welche Daten im Rahmen einer konkreten Konferenz
              gefordert werden (z.B. Angabe von Zugangsdaten oder Klarnamen) und
              welche optionalen Angaben durch die Teilnehmer getätigt werden.
              Neben der Verarbeitung zur Durchführung der Konferenz, können die
              Daten der Teilnehmer durch die Konferenzplattformen ebenfalls zu
              Sicherheitszwecken oder Serviceoptimierung verarbeitet werden. Zu
              den verarbeiteten Daten gehören Daten zur Person (Vorname,
              Nachname), Kontaktinformationen (E-Mail-Adresse, Telefonnummer),
              Zugangsdaten (Zugangscodes oder Passwörter), Profilbilder, Angaben
              zur beruflichen Stellung/Funktion, die IP-Adresse des
              Internetzugangs.{" "}
            </p>

            <p>
              <strong>Google Hangouts / Meet</strong>, <strong>Skype</strong>,{" "}
              <strong>Zoom</strong>:
            </p>

            <ul>
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B.
                Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
                Inhaltsdaten (z.B. Eingaben in Onlineformularen), Nutzungsdaten
                (z.B. besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Geräte-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Kommunikationspartner,
                Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Erbringung
                vertraglicher Leistungen und Kundenservice, Kontaktanfragen und
                Kommunikation, Büro- und Organisationsverfahren.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a. DSGVO), Vertragserfüllung und vorvertragliche
                Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte
                Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>

            <h2>Cloud-Dienste</h2>

            <p>
              Wir nutzen über das Internet zugängliche und auf den Servern ihrer
              Anbieter ausgeführte Softwaredienste (sogenannte “Cloud-Dienste”,
              auch bezeichnet als “Software as a Service”) für die folgenden
              Zwecke: Dokumentenspeicherung und Verwaltung, Kalenderverwaltung,
              E-Mail-Versand, Tabellenkalkulationen und Präsentationen,
              Austausch von Dokumenten, Inhalten und Informationen mit
              bestimmten Empfängern oder Veröffentlichung von Webseiten,
              Formularen oder sonstigen Inhalten und Informationen sowie Chats
              und Teilnahme an Audio- und Videokonferenzen.
            </p>

            <p>
              In diesem Rahmen können personenbezogenen Daten verarbeitet und
              auf den Servern der Anbieter gespeichert werden, soweit diese
              Bestandteil von Kommunikationsvorgängen mit uns sind oder von uns
              sonst, wie im Rahmen dieser Datenschutzerklärung dargelegt,
              verarbeitet werden. Zu diesen Daten können insbesondere Stammdaten
              und Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen,
              sonstigen Prozessen und deren Inhalte gehören. Die Anbieter der
              Cloud-Dienste verarbeiten ferner{" "}
            </p>

            <p>
              Nutzungsdaten und Metadaten, die von ihnen zu Sicherheitszwecken
              und zur Serviceoptimierung verwendet werden.
            </p>

            <p>
              Sofern wir mit Hilfe der Cloud-Dienste für andere Nutzer oder
              öffentlich zugängliche Webseiten Formulare o.a. Dokumente und
              Inhalte bereitstellen, können die Anbieter Cookies auf den Geräten
              der Nutzer für Zwecke der Webanalyse oder, um sich Einstellungen
              der Nutzer (z.B. im Fall der Mediensteuerung) zu merken,
              speichern.
            </p>

            <p>
              Hinweise zu Rechtsgrundlagen: Sofern wir um eine Einwilligung in
              den Einsatz der Cloud-Dienste bitten, ist die Rechtsgrundlage der
              Verarbeitung die Einwilligung. Ferner kann deren Einsatz ein
              Bestandteil unserer (vor)vertraglichen Leistungen sein, sofern der
              Einsatz der Cloud-Dienste in diesem Rahmen vereinbart wurde.
              Ansonsten werden die Daten der Nutzer auf Grundlage unserer
              berechtigten Interessen (d.h., Interesse an effizienten und
              sicheren Verwaltungs- und Kollaborationsprozessen) verarbeitet.
            </p>

            <p>
              <strong>Google Cloud-Dienste</strong>:
            </p>

            <ul>
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B.
                Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
                Inhaltsdaten (z.B. Eingaben in Onlineformularen), Nutzungsdaten
                (z.B. besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Geräte-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Kunden, Beschäftigte (z.B.
                Angestellte, Bewerber, ehemalige Mitarbeiter), Interessenten,
                Kommunikationspartner.
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Büro- und
                Organisationsverfahren.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a. DSGVO), Vertragserfüllung und vorvertragliche
                Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte
                Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>

            <p></p>

            <h2>Newsletter und elektronische Benachrichtigungen</h2>

            <p>
              Wir versenden Newsletter, E-Mails und weitere elektronische
              Benachrichtigungen (nachfolgend “Newsletter“) nur mit der
              Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis.
              Sofern im Rahmen einer Anmeldung zum Newsletter dessen Inhalte
              konkret umschrieben werden, sind sie für die Einwilligung der
              Nutzer maßgeblich. Im Übrigen enthalten unsere Newsletter
              Informationen zu unseren Leistungen und uns.
            </p>

            <p>
              Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich
              aus, wenn Sie Ihre E-Mail-Adresse angeben. Wir können Sie jedoch
              bitten, einen Namen, zwecks persönlicher Ansprache im Newsletter,
              oder weitere Angaben, sofern diese für die Zwecke des Newsletters
              erforderlich sind, zu tätigen.
            </p>

            <p>
              <strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem
              Newsletter erfolgt grundsätzlich in einem sogenannte
              Double-Opt-In-Verfahren. D.h., Sie erhalten nach der Anmeldung
              eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten
              werden. Diese Bestätigung ist notwendig, damit sich niemand mit
              fremden E-Mail-Adressen anmelden kann. Die Anmeldungen zum
              Newsletter werden protokolliert, um den Anmeldeprozess
              entsprechend den rechtlichen Anforderungen nachweisen zu können.
              Hierzu gehört die Speicherung des Anmelde- und des
              Bestätigungszeitpunkts als auch der IP-Adresse. Ebenso werden die
              Änderungen Ihrer bei dem Versanddienstleister gespeicherten Daten
              protokolliert.
            </p>

            <p>
              <strong>Löschung und Einschränkung der Verarbeitung:</strong> Wir
              können die ausgetragenen E-Mail-Adressen bis zu drei Jahren auf
              Grundlage unserer berechtigten Interessen speichern, bevor wir sie
              löschen, um eine ehemals gegebene Einwilligung nachweisen zu
              können. Die Verarbeitung dieser Daten wird auf den Zweck einer
              möglichen Abwehr von Ansprüchen beschränkt. Ein individueller
              Löschungsantrag ist jederzeit möglich, sofern zugleich das
              ehemalige Bestehen einer Einwilligung bestätigt wird. Im Fall von
              Pflichten zur dauerhaften Beachtung von Widersprüchen behalten wir
              uns die Speicherung der E-Mail-Adresse alleine zu diesem Zweck in
              einer Sperrliste (sogenannte “Blocklist”) vor.
            </p>

            <p>
              Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage
              unserer berechtigten Interessen zu Zwecken des Nachweises seines
              ordnungsgemäßen Ablaufs. Soweit wir einen Dienstleister mit dem
              Versand von E-Mails beauftragen, erfolgt dies auf Grundlage
              unserer berechtigten Interessen an einem effizienten und sicheren
              Versandsystem.
            </p>

            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Der Versand der
              Newsletter erfolgt auf Grundlage einer Einwilligung der Empfänger
              oder, falls eine Einwilligung nicht erforderlich ist, auf
              Grundlage unserer berechtigten Interessen am Direktmarketing,
              sofern und soweit diese gesetzlich, z.B. im Fall von
              Bestandskundenwerbung, erlaubt ist. Soweit wir einen Dienstleister
              mit dem Versand von E-Mails beauftragen, geschieht dies auf der
              Grundlage unserer berechtigten Interessen. Das
              Registrierungsverfahren wird auf der Grundlage unserer
              berechtigten Interessen aufgezeichnet, um nachzuweisen, dass es in
              Übereinstimmung mit dem Gesetz durchgeführt wurde.
            </p>

            <p>
              Inhalte: Informationen zu uns, unseren Leistungen, Aktionen und
              Angeboten.
            </p>

            <p>
              <strong>Messung von Öffnungs- und Klickraten</strong>:
            </p>

            <p>
              Die Newsletter enthalten einen sogenannte “web-beacon“, d.h., eine
              pixelgroße Datei, die beim Öffnen des Newsletters von unserem
              Server, bzw., sofern wir einen Versanddienstleister einsetzen, von
              dessen Server abgerufen wird. Im Rahmen dieses Abrufs werden
              zunächst technische Informationen, wie Informationen zum Browser
              und Ihrem System, als auch Ihre IP-Adresse und der Zeitpunkt des
              Abrufs, erhoben.
            </p>

            <p>
              Diese Informationen werden zur technischen Verbesserung unseres
              Newsletters anhand der technischen Daten oder der Zielgruppen und
              ihres Leseverhaltens auf Basis ihrer Abruforte (die mit Hilfe der
              IP-Adresse bestimmbar sind) oder der Zugriffszeiten genutzt. Diese
              Analyse beinhaltet ebenfalls die Feststellung, ob die Newsletter
              geöffnet werden, wann sie geöffnet werden und welche Elemente
              angeklickt werden.
            </p>

            <ul>
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B.
                Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen), Nutzungsdaten (z.B. besuchte Webseiten, Interesse
                an Inhalten, Zugriffszeiten).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Kommunikationspartner,
                Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B.
                per E-Mail oder postalisch), Reichweitenmessung (z.B.
                Zugriffsstatistiken, Erkennung wiederkehrender Besucher),
                Konversionsmessung (Messung der Effektivität von
                Marketingmaßnahmen), Profile mit nutzerbezogenen Informationen
                (Erstellen von Nutzerprofilen).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                lit. f. DSGVO).
              </li>
              <li>
                <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Sie können
                den Empfang unseres Newsletters jederzeit kündigen, d.h. Ihre
                Einwilligungen widerrufen, bzw. dem weiteren Empfang
                widersprechen. Einen Link zur Kündigung des Newsletters finden
                Sie entweder am Ende eines jeden Newsletters oder können sonst
                eine der oben angegebenen Kontaktmöglichkeiten, vorzugswürdig
                E-Mail, hierzu nutzen.
              </li>
            </ul>

            <h2>Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</h2>

            <p>
              Wir verarbeiten personenbezogene Daten zu Zwecken der werblichen
              Kommunikation, die über diverse Kanäle, wie z.B. E-Mail, Telefon,
              Post oder Fax, entsprechend den gesetzlichen Vorgaben erfolgen
              kann.
            </p>

            <p>
              Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit
              zu widerrufen oder der werblichen Kommunikation jederzeit zu
              widersprechen.
            </p>

            <p>
              Nach Widerruf bzw. Widerspruch können wir die zum Nachweis der
              Einwilligung erforderlichen Daten bis zu drei Jahren auf Grundlage
              unserer berechtigten Interessen speichern, bevor wir sie löschen.
              Die Verarbeitung dieser Daten wird auf den Zweck einer möglichen
              Abwehr von Ansprüchen beschränkt. Ein individueller
              Löschungsantrag ist jederzeit möglich, sofern zugleich das
              ehemalige Bestehen einer Einwilligung bestätigt wird.
            </p>

            <ul>
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B.
                Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Kommunikationspartner.
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B.
                per E-Mail oder postalisch).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                lit. f. DSGVO).
              </li>
            </ul>

            <h2>Umfragen und Befragungen</h2>

            <p>
              Die von uns durchgeführten Umfragen und Befragungen (nachfolgend
              “Befragungen”) werden anonym ausgewertet. Eine Verarbeitung
              personenbezogener Daten erfolgt nur insoweit, als dies zu
              Bereitstellung und technischen Durchführung der Umfragen
              erforderlich ist (z.B. Verarbeitung der IP-Adresse, um die Umfrage
              im Browser des Nutzers darzustellen oder mithilfe eines temporären
              Cookies (Session-Cookie) eine Wiederaufnahme der Umfrage zu
              ermöglichen) oder Nutzer eingewilligt haben.
            </p>

            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die
              Teilnehmer um eine Einwilligung in die Verarbeitung iherer Daten
              bitten, ist diese Rechtsgrundlage der Verarbeitung, ansonsten
              erfolgt die Verarbeitung der Daten der Teilnehmer auf Grundlage
              unserer berechtigten Interessen an der Durchführung einer
              objektiven Umfrage.
            </p>

            <ul>
              <li>
                <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B.
                E-Mail, Telefonnummern), Inhaltsdaten (z.B. Eingaben in
                Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten,
                Interesse an Inhalten, Zugriffszeiten),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Kommunikationspartner.
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
                Kommunikation, Direktmarketing (z.B. per E-Mail oder
                postalisch).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                lit. f. DSGVO).
              </li>
            </ul>

            <p>
              <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
            </p>

            <ul>
              <li>
                <strong>Google-Formular:</strong> Google-Cloud-Formulare;
                Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
                Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
                Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:{" "}
                <a
                  href="https://firebase.google.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://firebase.google.com
                </a>
                ; Datenschutzerklärung:{" "}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://policies.google.com/privacy
                </a>
                ; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout?hl=de"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://tools.google.com/dlpage/gaoptout?hl=de
                </a>
                , Einstellungen für die Darstellung von Werbeeinblendungen:{" "}
                <a
                  href="https://adssettings.google.com/authenticated"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://adssettings.google.com/authenticated
                </a>
                .
              </li>
            </ul>

            <h2>Webanalyse, Monitoring und Optimierung</h2>

            <p>
              Die Webanalyse (auch als “Reichweitenmessung” bezeichnet) dient
              der Auswertung der Besucherströme unseres Onlineangebotes und kann
              Verhalten, Interessen oder demographische Informationen zu den
              Besuchern, wie z.B. das Alter oder das Geschlecht, als pseudonyme
              Werte umfassen. Mit Hilfe der Reichweitenanalyse können wir z.B.
              erkennen, zu welcher Zeit unser Onlineangebot oder dessen
              Funktionen oder Inhalte am häufigsten genutzt werden oder zur
              Wiederverwendung einladen. Ebenso können wir nachvollziehen,
              welche Bereiche der Optimierung bedürfen.
            </p>

            <p>
              Neben der Webanalyse können wir auch Testverfahren einsetzen, um
              z.B. unterschiedliche Versionen unseres Onlineangebotes oder
              seiner Bestandteile zu testen und optimieren.
            </p>

            <p>
              Zu diesen Zwecken können sogenannte Nutzerprofile angelegt und in
              einer Datei (sogenannte “Cookie”) gespeichert oder ähnliche
              Verfahren mit dem gleichen Zweck genutzt werden. Zu diesen Angaben
              können z.B. betrachtete Inhalte, besuchte Webseiten und dort
              genutzte Elemente und technische Angaben, wie der verwendete
              Browser, das verwendete Computersystem sowie Angaben zu
              Nutzungszeiten gehören. Sofern Nutzer in die Erhebung ihrer
              Standortdaten eingewilligt haben, können je nach Anbieter auch
              diese verarbeitet werden.
            </p>

            <p>
              Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
              nutzen wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch
              Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die
              im Rahmen von Webanalyse, A/B-Testings und Optimierung keine
              Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen)
              gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter
              der eingesetzten Software kennen nicht die tatsächliche Identität
              der Nutzer, sondern nur den für Zwecke der jeweiligen Verfahren in
              deren Profilen gespeicherten Angaben.
            </p>

            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die
              Nutzer um deren Einwilligung in den Einsatz der Drittanbieter
              bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
              Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage
              unserer berechtigten Interessen (d.h. Interesse an effizienten,
              wirtschaftlichen und empfängerfreundlichen Leistungen)
              verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die
              Informationen zur Verwendung von Cookies in dieser
              Datenschutzerklärung hinweisen.
            </p>

            <ul>
              <li>
                <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B.
                besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung
                (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher),
                Profile mit nutzerbezogenen Informationen (Erstellen von
                Nutzerprofilen).
              </li>
              <li>
                <strong>Sicherheitsmaßnahmen:</strong> IP-Masking
                (Pseudonymisierung der IP-Adresse).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                lit. f. DSGVO).
              </li>
            </ul>

            <p>
              <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
            </p>

            <ul>
              <li>
                <strong>Google Analytics:</strong> Reichweitenmessung und
                Webanalyse; Dienstanbieter: Google Ireland Limited, Gordon
                House, Barrow Street, Dublin 4, Irland, Mutterunternehmen:
                Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
                USA; Website:{" "}
                <a
                  href="https://marketingplatform.google.com/intl/de/about/analytics/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://marketingplatform.google.com/intl/de/about/analytics/
                </a>
                ; Datenschutzerklärung:{" "}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://policies.google.com/privacy
                </a>
                .
              </li>
              <li>
                <strong>Google Tag Manager:</strong> Google Tag Manager ist eine
                Lösung, mit der wir sog. Website-Tags über eine Oberfläche
                verwalten und so andere Dienste in unser Onlineangebot einbinden
                können (hierzu wird auf weitere Angaben in dieser
                Datenschutzerklärung verwiesen). Mit dem Tag Manager selbst
                (welches die Tags implementiert) werden daher z. B. noch keine
                Profile der Nutzer erstellt oder Cookies gespeichert. Google
                erfährt lediglich die IP-Adresse des Nutzers, was notwendig ist,
                um den Google Tag Manager auszuführen. Dienstanbieter: Google
                Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
                Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway,
                Mountain View, CA 94043, USA; Website:{" "}
                <a
                  href="https://marketingplatform.google.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://marketingplatform.google.com
                </a>
                ; Datenschutzerklärung:{" "}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://policies.google.com/privacy
                </a>
                .
              </li>
              <li>
                <strong>Matomo:</strong> Die durch das Cookie erzeugte
                Informationen über Ihre Benutzung dieser Webseite werden nur auf
                unserem Server gespeichert und nicht an Dritte weitergegeben;
                Dienstanbieter: Webanalyse/ Reichweitenmessung im Selbsthosting;
                Website:{" "}
                <a
                  href="https://matomo.org/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://matomo.org/
                </a>
                ; Löschung von Daten: Die Cookies haben eine Speicherdauer von
                maximal 13 Monaten.
              </li>
            </ul>

            <h2>Onlinemarketing</h2>

            <p>
              Wir verarbeiten personenbezogene Daten zu Zwecken des
              Onlinemarketings, worunter insbesondere die Vermarktung von
              Werbeflächen oder Darstellung von werbenden und sonstigen Inhalten
              (zusammenfassend als “Inhalte” bezeichnet) anhand potentieller
              Interessen der Nutzer sowie die Messung ihrer Effektivität fallen
              kann.
            </p>

            <p>
              Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in
              einer Datei (sogenannte “Cookie”) gespeichert oder ähnliche
              Verfahren genutzt, mittels derer die für die Darstellung der
              vorgenannten Inhalte relevante Angaben zum Nutzer gespeichert
              werden. Zu diesen Angaben können z.B. betrachtete Inhalte,
              besuchte Webseiten, genutzte Onlinenetzwerke, aber auch
              Kommunikationspartner und technische Angaben, wie der verwendete
              Browser, das verwendete Computersystem sowie Angaben zu
              Nutzungszeiten gehören. Sofern Nutzer in die Erhebung ihrer
              Standortdaten eingewilligt haben, können auch diese verarbeitet
              werden.
            </p>

            <p>
              Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
              nutzen wir zur Verfügung stehende IP-Masking-Verfahren (d.h.,
              Pseudonymisierung durch Kürzung der IP-Adresse) zum Schutz der
              Nutzer. Generell werden im Rahmen des Onlinemarketingverfahren
              keine Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen)
              gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter
              der Onlinemarketingverfahren kennen nicht die tatsächlich
              Identität der Nutzer, sondern nur die in deren Profilen
              gespeicherten Angaben.
            </p>

            <p>
              Die Angaben in den Profilen werden im Regelfall in den Cookies
              oder mittels ähnlicher Verfahren gespeichert. Diese Cookies können
              später generell auch auf anderen Webseiten die dasselbe
              Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken der
              Darstellung von Inhalten analysiert als auch mit weiteren Daten
              ergänzt und auf dem Server des Onlinemarketingverfahrensanbieters
              gespeichert werden.
            </p>

            <p>
              Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das
              ist der Fall, wenn die Nutzer z.B. Mitglieder eines sozialen
              Netzwerks sind, dessen Onlinemarketingverfahren wir einsetzen und
              das Netzwerk die Profile der Nutzer mit den vorgenannten Angaben
              verbindet. Wir bitten darum, zu beachten, dass Nutzer mit den
              Anbietern zusätzliche Abreden, z.B. durch Einwilligung im Rahmen
              der Registrierung, treffen können.
            </p>

            <p>
              Wir erhalten grundsätzlich nur Zugang zu zusammengefassten
              Informationen über den Erfolg unserer Werbeanzeigen. Jedoch können
              wir im Rahmen sogenannter Konversionsmessungen prüfen, welche
              unserer Onlinemarketingverfahren zu einer sogenannten Konversion
              geführt haben, d.h. z.B., zu einem Vertragsschluss mit uns. Die
              Konversionsmessung wird alleine zur Analyse des Erfolgs unserer
              Marketingmaßnahmen verwendet.
            </p>

            <p>
              Solange nicht anders angegeben, bitten wir Sie davon auszugehen,
              dass verwendete Cookies für einen Zeitraum von zwei Jahren
              gespeichert werden.
            </p>

            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die
              Nutzer um deren Einwilligung in den Einsatz der Drittanbieter
              bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
              Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage
              unserer berechtigten Interessen (d.h. Interesse an effizienten,
              wirtschaftlichen und empfängerfreundlichen Leistungen)
              verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die
              Informationen zur Verwendung von Cookies in dieser
              Datenschutzerklärung hinweisen.
            </p>

            <p></p>

            <p>
              <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
            </p>

            <ul>
              <li>
                <strong>Google Tag Manager:</strong> Google Tag Manager ist eine
                Lösung, mit der wir sog. Website-Tags über eine Oberfläche
                verwalten und so andere Dienste in unser Onlineangebot einbinden
                können (hierzu wird auf weitere Angaben in dieser
                Datenschutzerklärung verwiesen). Mit dem Tag Manager selbst
                (welches die Tags implementiert) werden daher z. B. noch keine
                Profile der Nutzer erstellt oder Cookies gespeichert. Google
                erfährt lediglich die IP-Adresse des Nutzers, was notwendig ist,
                um den Google Tag Manager auszuführen. Dienstanbieter: Google
                Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
                Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway,
                Mountain View, CA 94043, USA; Website:{" "}
                <a
                  rel="noreferrer noopener"
                  href="https://marketingplatform.google.com"
                  target="_blank"
                >
                  https://marketingplatform.google.com
                </a>
                ; Datenschutzerklärung:{" "}
                <a
                  rel="noreferrer noopener"
                  href="https://policies.google.com/privacy"
                  target="_blank"
                >
                  https://policies.google.com/privacy
                </a>
                .
              </li>
              <li>
                <strong>Google Analytics:</strong> Onlinemarketing und
                Webanalyse; Dienstanbieter: Google Ireland Limited, Gordon
                House, Barrow Street, Dublin 4, Irland, Mutterunternehmen:
                Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
                USA; Website:{" "}
                <a
                  rel="noreferrer noopener"
                  href="https://marketingplatform.google.com/intl/de/about/analytics/"
                  target="_blank"
                >
                  https://marketingplatform.google.com/intl/de/about/analytics/
                </a>
                ; Datenschutzerklärung:{" "}
                <a
                  rel="noreferrer noopener"
                  href="https://policies.google.com/privacy"
                  target="_blank"
                >
                  https://policies.google.com/privacy
                </a>
                ; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:{" "}
                <a
                  rel="noreferrer noopener"
                  href="https://tools.google.com/dlpage/gaoptout?hl=de"
                  target="_blank"
                >
                  https://tools.google.com/dlpage/gaoptout?hl=de
                </a>
                , Einstellungen für die Darstellung von Werbeeinblendungen:{" "}
                <a
                  rel="noreferrer noopener"
                  href="https://adssettings.google.com/authenticated"
                  target="_blank"
                >
                  https://adssettings.google.com/authenticated
                </a>
                .
              </li>
              <li>
                <strong>Zielgruppenbildung mit Google Analytics:</strong> Wir
                setzen Google Analytics ein, um die durch innerhalb von
                Werbediensten Googles und seiner Partner geschalteten Anzeigen,
                nur solchen Nutzern anzuzeigen, die auch ein Interesse an
                unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale
                (z.B. Interessen an bestimmten Themen oder Produkten, die anhand
                der besuchten Webseiten bestimmt werden) aufweisen, die wir an
                Google übermitteln (sog. “Remarketing-“, bzw.
                “Google-Analytics-Audiences“). Mit Hilfe der Remarketing
                Audiences möchten wir auch sicherstellen, dass unsere Anzeigen
                dem potentiellen Interesse der Nutzer entsprechen;
                Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
                Street, Dublin 4, Ireland, parent company: Google LLC, 1600
                Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:{" "}
                <a
                  rel="noreferrer noopener"
                  href="https://marketingplatform.google.com"
                  target="_blank"
                >
                  https://marketingplatform.google.com
                </a>
                ; Datenschutzerklärung:{" "}
                <a
                  rel="noreferrer noopener"
                  href="https://policies.google.com/privacy"
                  target="_blank"
                >
                  https://policies.google.com/privacy
                </a>
                .
              </li>
            </ul>

            <h2>Affiliate-Programme und Affiliate-Links</h2>

            <p>
              In unser Onlineangebot binden wir sogenannte Affiliate-Links oder
              andere Verweise (zu denen z.B. Suchmasken, Widgets oder
              Rabatt-Codes gehören können) auf die Angebote und Leistungen von
              Drittanbietern ein (zusammenfassend bezeichnet als
              “Affiliate-Links”). Wenn Nutzer den Affiliate-Links folgen, bzw.
              anschließend die Angebote wahrnehmen, können wir von diesen
              Drittanbietern eine Provision oder sonstige Vorteile erhalten
              (zusammenfassend bezeichnet als “Provision”).
            </p>

            <p>
              Um nachverfolgen zu können, ob die Nutzer die Angebote eines von
              uns eingesetzten Affiliate-Links wahrgenommen haben, ist es
              notwendig, dass die jeweiligen Drittanbieter erfahren, dass die
              Nutzer einem innerhalb unseres Onlineangebotes eingesetzten
              Affiliate-Link gefolgt sind. Die Zuordnung der Affiliate-Links zu
              den jeweiligen Geschäftsabschlüssen oder zu sonstigen Aktionen
              (z.B. Käufen) dient alleine dem Zweck der Provisionsabrechnung und
              wird aufgehoben, sobald sie für den Zweck nicht mehr erforderlich
              ist.
            </p>

            <p>
              Für die Zwecke der vorgenannten Zuordnung der Affiliate-Links
              können die Affiliate-Links um bestimmte Werte ergänzt werden, die
              ein Bestandteil des Links sind oder anderweitig, z.B. in einem
              Cookie, gespeichert werden können. Zu den Werten können
              insbesondere die Ausgangswebseite (Referrer), der Zeitpunkt, eine
              Online-Kennung der Betreiber der Webseite, auf der sich der
              Affiliate-Link befand, eine Online-Kennung des jeweiligen
              Angebotes, die Art des verwendeten Links, die Art des Angebotes
              und eine Online-Kennung des Nutzers gehören.
            </p>

            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die
              Nutzer um deren Einwilligung in den Einsatz der Drittanbieter
              bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
              Einwilligung. Ferner kann deren Einsatz ein Bestandteil unserer
              (vor)vertraglichen Leistungen sein, sofern der Einsatz der
              Drittanbieter in diesem Rahmen vereinbart wurde. Ansonsten werden
              die Daten der Nutzer auf Grundlage unserer berechtigten Interessen
              (d.h. Interesse an effizienten, wirtschaftlichen und
              empfängerfreundlichen Leistungen) verarbeitet. In diesem
              Zusammenhang möchten wir Sie auch auf die Informationen zur
              Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
            </p>

            <p>
              <strong>
                AWIN-Partnerprogramm (ehemals Zanox und Affilinet)
              </strong>
              :
            </p>

            <ul>
              <li>
                <strong>Verarbeitete Datenarten:</strong> Vertragsdaten (z.B.
                Vertragsgegenstand, Laufzeit, Kundenkategorie), Nutzungsdaten
                (z.B. besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Geräte-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong>{" "}
                Affiliate-Nachverfolgung.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a. DSGVO), Vertragserfüllung und vorvertragliche
                Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte
                Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                <br />
              </li>
            </ul>

            <p>
              <strong>Tradedoubler-Partnerprogramm</strong>:
            </p>

            <ul>
              <li>
                <strong>Verarbeitete Datenarten:</strong> Vertragsdaten (z.B.
                Vertragsgegenstand, Laufzeit, Kundenkategorie), Nutzungsdaten
                (z.B. besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Geräte-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong>{" "}
                Affiliate-Nachverfolgung.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a. DSGVO), Vertragserfüllung und vorvertragliche
                Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte
                Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>

            <p>
              <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
            </p>

            <ul>
              <li>
                <strong>Amazon-Partnerprogramm:</strong> Amazon –
                Partnerprogramm – Amazon und das Amazon-Logo sind Warenzeichen
                von Amazon.com, Inc. oder eines seiner verbundenen Unternehmen.
                Dienstanbieter: Amazon Europe Core S.à.r.l., die Amazon EU
                S.à.r.l, die Amazon Services Europe S.à.r.l. und die Amazon
                Media EU S.à.r.l., alle vier ansässig in 38, avenue John F.
                Kennedy, L-1855 Luxemburg, sowie Amazon Instant Video Germany
                GmbH, Domagkstr. 28, 80807 München (zusammen “Amazon Europe”),
                Mutterunternehmen: Amazon.com, Inc., 2021 Seventh Ave, Seattle,
                Washington 98121, USA.; Website:{" "}
                <a
                  rel="noreferrer noopener"
                  href="https://www.amazon.de"
                  target="_blank"
                >
                  https://www.amazon.de
                </a>
                ; Datenschutzerklärung:{" "}
                <a
                  rel="noreferrer noopener"
                  href="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010"
                  target="_blank"
                >
                  https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010
                </a>
                .
              </li>
            </ul>

            <h2>Präsenzen in sozialen Netzwerken (Social Media)</h2>

            <p>
              Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
              verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort
              aktiven Nutzern zu kommunizieren oder um Informationen über uns
              anzubieten.
            </p>

            <p>
              Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des
              Raumes der Europäischen Union verarbeitet werden können. Hierdurch
              können sich für die Nutzer Risiken ergeben, weil so z.B. die
              Durchsetzung der Rechte der Nutzer erschwert werden könnte.
            </p>

            <p>
              Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
              Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So
              können z.B. anhand des Nutzungsverhaltens und sich daraus
              ergebender Interessen der Nutzer Nutzungsprofile erstellt werden.
              Die Nutzungsprofile können wiederum verwendet werden, um z.B.
              Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten,
              die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen
              Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer
              gespeichert, in denen das Nutzungsverhalten und die Interessen der
              Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen
              auch Daten unabhängig der von den Nutzern verwendeten Geräte
              gespeichert werden (insbesondere, wenn die Nutzer Mitglieder der
              jeweiligen Plattformen sind und bei diesen eingeloggt sind).
            </p>

            <p>
              Für eine detaillierte Darstellung der jeweiligen
              Verarbeitungsformen und der Widerspruchsmöglichkeiten (Opt-Out)
              verweisen wir auf die Datenschutzerklärungen und Angaben der
              Betreiber der jeweiligen Netzwerke.
            </p>

            <p>
              Auch im Fall von Auskunftsanfragen und der Geltendmachung von
              Betroffenenrechten weisen wir darauf hin, dass diese am
              effektivsten bei den Anbietern geltend gemacht werden können. Nur
              die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und
              können direkt entsprechende Maßnahmen ergreifen und Auskünfte
              geben. Sollten Sie dennoch Hilfe benötigen, dann können Sie sich
              an uns wenden.
            </p>

            <ul>
              <li>
                <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B.
                E-Mail, Telefonnummern), Inhaltsdaten (z.B. Eingaben in
                Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten,
                Interesse an Inhalten, Zugriffszeiten),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
                Kommunikation, Feedback (z.B. Sammeln von Feedback via
                Online-Formular), Marketing.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art.
                6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>

            <p>
              <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
            </p>

            <ul>
              <li>
                <strong>Instagram:</strong> Soziales Netzwerk; Dienstanbieter:
                Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA,
                Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025,
                USA; Website:{" "}
                <a
                  href="https://www.instagram.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://www.instagram.com
                </a>
                ; Datenschutzerklärung:{" "}
                <a
                  href="https://instagram.com/about/legal/privacy"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://instagram.com/about/legal/privacy
                </a>
                .
              </li>
            </ul>

            <h2>Änderung und Aktualisierung der Datenschutzerklärung</h2>

            <p>
              Wir bitten Sie, sich regelmäßig über den Inhalt unserer
              Datenschutzerklärung zu informieren. Wir passen die
              Datenschutzerklärung an, sobald die Änderungen der von uns
              durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
              informieren Sie, sobald durch die Änderungen eine
              Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine
              sonstige individuelle Benachrichtigung erforderlich wird.
            </p>

            <p>
              Sofern wir in dieser Datenschutzerklärung Adressen und
              Kontaktinformationen von Unternehmen und Organisationen angeben,
              bitten wir zu beachten, dass die Adressen sich über die Zeit
              ändern können und bitten die Angaben vor Kontaktaufnahme zu
              prüfen.
            </p>

            <h2>Rechte der betroffenen Personen</h2>

            <p>
              Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu,
              die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:
            </p>

            <ul>
              <li>
                <strong>
                  Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich
                  aus Ihrer besonderen Situation ergeben, jederzeit gegen die
                  Verarbeitung der Sie betreffenden personenbezogenen Daten, die
                  aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
                  Widerspruch einzulegen; dies gilt auch für ein auf diese
                  Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
                  personenbezogenen Daten verarbeitet, um Direktwerbung zu
                  betreiben, haben Sie das Recht, jederzeit Widerspruch gegen
                  die Verarbeitung der Sie betreffenden personenbezogenen Daten
                  zum Zwecke derartiger Werbung einzulegen; dies gilt auch für
                  das Profiling, soweit es mit solcher Direktwerbung in
                  Verbindung steht.
                </strong>
              </li>
              <li>
                <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben
                das Recht, erteilte Einwilligungen jederzeit zu widerrufen.
              </li>
              <li>
                <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
                Bestätigung darüber zu verlangen, ob betreffende Daten
                verarbeitet werden und auf Auskunft über diese Daten sowie auf
                weitere Informationen und Kopie der Daten entsprechend den
                gesetzlichen Vorgaben.
              </li>
              <li>
                <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend
                den gesetzlichen Vorgaben das Recht, die Vervollständigung der
                Sie betreffenden Daten oder die Berichtigung der Sie
                betreffenden unrichtigen Daten zu verlangen.
              </li>
              <li>
                <strong>
                  Recht auf Löschung und Einschränkung der Verarbeitung:
                </strong>{" "}
                Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
                verlangen, dass Sie betreffende Daten unverzüglich gelöscht
                werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben
                eine Einschränkung der Verarbeitung der Daten zu verlangen.
              </li>
              <li>
                <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
                Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben,
                nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten,
                gängigen und maschinenlesbaren Format zu erhalten oder deren
                Übermittlung an einen anderen Verantwortlichen zu fordern.
              </li>
              <li>
                <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben
                unbeschadet eines anderweitigen verwaltungsrechtlichen oder
                gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
                Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts
                des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die
                Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
                die Vorgaben der DSGVO verstößt.
              </li>
            </ul>

            <h2>Begriffsdefinitionen</h2>

            <p>
              In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
              Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der
              Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO
              definiert. Die gesetzlichen Definitionen sind verbindlich. Die
              nachfolgenden Erläuterungen sollen dagegen vor allem dem
              Verständnis dienen. Die Begriffe sind alphabetisch sortiert.
            </p>

            <ul>
              <li>
                <strong>Affiliate-Nachverfolgung:</strong> Im Rahmen der
                Affiliate-Nachverfolgung werden Links, mit deren Hilfe die
                verlinkenden Webseiten Nutzer zu Webseiten mit Produkt- oder
                sonstigen Angeboten verweisen, protokolliert. Die Betreiber der
                jeweils verlinkenden Webseiten können eine Provision erhalten,
                wenn Nutzer diesen sogenannten Affiliate-Links folgen und
                anschließend die Angebote wahrnehmen (z.B. Waren kaufen oder
                Dienstleistungen in Anspruch nehmen). Hierzu ist es
                erforderlich, dass die Anbieter nachverfolgen können, ob Nutzer,
                die sich für bestimmte Angebote interessieren, diese
                anschließend auf die Veranlassung der Affiliate-Links
                wahrnehmen. Daher ist es für die Funktionsfähigkeit von
                Affiliate-Links erforderlich, dass sie um bestimmte Werte
                ergänzt werden, die ein Bestandteil des Links werden oder
                anderweitig, z.B. in einem Cookie, gespeichert werden. Zu den
                Werten gehören insbesondere die Ausgangswebseite (Referrer), der
                Zeitpunkt, eine Online-Kennung der Betreiber der Webseite, auf
                der sich der Affiliate-Link befand, eine Online-Kennung des
                jeweiligen Angebotes, eine Online-Kennung des Nutzers als auch
                nachverfolgungsspezifische Werte, wie, z.B. Werbemittel-ID,
                Partner-ID und Kategorisierungen
              </li>
              <li>
                <strong>IP-Masking:</strong> Als “IP-Masking” wird eine Methode
                bezeichnet, bei der das letzte Oktett, d.h., die letzten beiden
                Zahlen einer IP-Adresse, gelöscht wird, damit die IP-Adresse
                nicht mehr der eindeutigen Identifizierung einer Person dienen
                kann. Daher ist das IP-Masking ein Mittel zur Pseudonymisierung
                von Verarbeitungsverfahren, insbesondere im Onlinemarketing
              </li>
              <li>
                <strong>Konversionsmessung:</strong> Die Konversionsmessung
                (auch als “Besuchsaktionsauswertung” bezeichnet) ist ein
                Verfahren, mit dem die Wirksamkeit von Marketingmaßnahmen
                festgestellt werden kann. Dazu wird im Regelfall ein Cookie auf
                den Geräten der Nutzer innerhalb der Webseiten, auf denen die
                Marketingmaßnahmen erfolgen, gespeichert und dann erneut auf der
                Zielwebseite abgerufen. Beispielsweise können wir so
                nachvollziehen, ob die von uns auf anderen Webseiten
                geschalteten Anzeigen erfolgreich waren.
              </li>
              <li>
                <strong>Personenbezogene Daten:</strong> “Personenbezogene
                Daten“ sind alle Informationen, die sich auf eine identifizierte
                oder identifizierbare natürliche Person (im Folgenden
                “betroffene Person“) beziehen; als identifizierbar wird eine
                natürliche Person angesehen, die direkt oder indirekt,
                insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
                zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
                (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen
                identifiziert werden kann, die Ausdruck der physischen,
                physiologischen, genetischen, psychischen, wirtschaftlichen,
                kulturellen oder sozialen Identität dieser natürlichen Person
                sind.
              </li>
              <li>
                <strong>Profile mit nutzerbezogenen Informationen:</strong> Die
                Verarbeitung von “Profilen mit nutzerbezogenen Informationen”,
                bzw. kurz “Profilen” umfasst jede Art der automatisierten
                Verarbeitung personenbezogener Daten, die darin besteht, dass
                diese personenbezogenen Daten verwendet werden, um bestimmte
                persönliche Aspekte, die sich auf eine natürliche Person
                beziehen (je nach Art der Profilbildung können dazu
                unterschiedliche Informationen betreffend die Demographie,
                Verhalten und Interessen, wie z.B. die Interaktion mit Webseiten
                und deren Inhalten, etc.) zu analysieren, zu bewerten oder, um
                sie vorherzusagen (z.B. die Interessen an bestimmten Inhalten
                oder Produkten, das Klickverhalten auf einer Webseite oder den
                Aufenthaltsort). Zu Zwecken des Profilings werden häufig Cookies
                und Web-Beacons eingesetzt.
              </li>
              <li>
                <strong>Reichweitenmessung:</strong> Die Reichweitenmessung
                (auch als Web Analytics bezeichnet) dient der Auswertung der
                Besucherströme eines Onlineangebotes und kann das Verhalten oder
                Interessen der Besucher an bestimmten Informationen, wie z.B.
                Inhalten von Webseiten, umfassen. Mit Hilfe der
                Reichweitenanalyse können Webseiteninhaber z.B. erkennen, zu
                welcher Zeit Besucher ihre Webseite besuchen und für welche
                Inhalte sie sich interessieren. Dadurch können sie z.B. die
                Inhalte der Webseite besser an die Bedürfnisse ihrer Besucher
                anpassen. Zu Zwecken der Reichweitenanalyse werden häufig
                pseudonyme Cookies und Web-Beacons eingesetzt, um wiederkehrende
                Besucher zu erkennen und so genauere Analysen zur Nutzung eines
                Onlineangebotes zu erhalten.
              </li>
              <li>
                <strong>Remarketing:</strong> Vom “Remarketing“ bzw.
                “Retargeting“ spricht man, wenn z.B. zu Werbezwecken vermerkt
                wird, für welche Produkte sich ein Nutzer auf einer Webseite
                interessiert hat, um den Nutzer auf anderen Webseiten an diese
                Produkte, z.B. in Werbeanzeigen, zu erinnern.
              </li>
              <li>
                <strong>Verantwortlicher:</strong> Als “Verantwortlicher“ wird
                die natürliche oder juristische Person, Behörde, Einrichtung
                oder andere Stelle, die allein oder gemeinsam mit anderen über
                die Zwecke und Mittel der Verarbeitung von personenbezogenen
                Daten entscheidet, bezeichnet.
              </li>
              <li>
                <strong>Verarbeitung:</strong> “Verarbeitung” ist jeder mit oder
                ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder
                jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen
                Daten. Der Begriff reicht weit und umfasst praktisch jeden
                Umgang mit Daten, sei es das Erheben, das Auswerten, das
                Speichern, das Übermitteln oder das Löschen.
              </li>
              <li>
                <strong>Zielgruppenbildung:</strong> Von Zielgruppenbildung
                (bzw. “Custom Audiences“) spricht man, wenn Zielgruppen für
                Werbezwecke, z.B. Einblendung von Werbeanzeigen, bestimmt
                werden. So kann z.B. anhand des Interesses eines Nutzers an
                bestimmten Produkten oder Themen im Internet geschlussfolgert
                werden, dass dieser Nutzer sich für Werbeanzeigen für ähnliche
                Produkte oder den Onlineshop, in dem er die Produkte betrachtet
                hat, interessiert. Von “Lookalike Audiences“ (bzw. ähnlichen
                Zielgruppen) spricht man wiederum, wenn die als geeignet
                eingeschätzten Inhalte Nutzern angezeigt werden, deren Profile
                bzw. Interessen mutmaßlich den Nutzern, zu denen die Profile
                gebildet wurden, entsprechen. Zu Zwecken der Bildung von Custom
                Audiences und Lookalike Audiences werden im Regelfall Cookies
                und Web-Beacons eingesetzt.
              </li>
            </ul>

            <p>
              <a
                href="https://datenschutz-generator.de/?l=de"
                target="_blank"
                rel="noreferrer noopener"
              >
                Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
                Schwenke
              </a>
            </p>
          </div>
        </article>
      </main>
    </Layout>
  )
}

export default Datenschutz
